import styled from "styled-components";

export const Heading = styled.h3`
  color: white;
  margin: 0;
  font-size: 1rem;
  margin-bottom: 12px;
  font-weight: 500;
`;

export const Br = styled.div`
  height: 20px;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  border: 0;
  background: transparent;
  color: white;
  font-weight: 400;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 3px;

  &:focus {
    outline: none;
  }
`;
